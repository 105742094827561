import type { LayoutProps } from '../../types';

import * as React from 'react';
import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useMediaQuery } from '@mui/material';
import { adsRequest } from '../../utils/ads-request';

const AdvertisementSlot = dynamic<any>(() => import('../../components/AdvertisementSlot').then((mod) => mod.AdvertisementSlot), {
  ssr: false,
});

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  height: 'auto',
}));

const Sides = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up(1200)]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexWrap: 'nowrap',
    height: 'fit-content',
    justifyContent: 'center',
    padding: theme.spacing(7, 2, 2),
  },
}));

const AdvertisementSlotStyled = styled(AdvertisementSlot)(({ theme }) => ({
  boxShadow: theme.shadows[0],
  margin: theme.spacing(0, 0, 4),
}));

export const LayoutB = (props: LayoutProps) => {
  const { children } = props;
  const router = useRouter();
  const xlCustom = useMediaQuery('(min-width:1560px)', { noSsr: true });
  const lgCustom = useMediaQuery('(max-width:1559px) and (min-width:1280px)', { noSsr: true });
  const mdCustom = useMediaQuery('(max-width:1279px) and (min-width:1200px)', { noSsr: true });
  const [leftSize, setLeftSize] = React.useState(0);
  const [rightSize, setRightSize] = React.useState(0);
  const [banners, setBanners] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (xlCustom) {
      setLeftSize(300);
      setRightSize(300);
    } else if (lgCustom) {
      setLeftSize(160);
      setRightSize(160);
    } else if (mdCustom) {
      setLeftSize(120);
      setRightSize(120);
    } else {
      setLeftSize(0);
      setRightSize(0);
    }
  }, [xlCustom, lgCustom, mdCustom]);

  React.useEffect(() => {
    const updatedBanners = [];
    if (leftSize > 0) {
      updatedBanners.push('idhb-left-300');
    }
    if (leftSize >= 300) {
      updatedBanners.push('idhb-left-small-300');
    }
    if (rightSize > 0) {
      updatedBanners.push('idhb-right-300');
    }
    if (rightSize >= 300) {
      updatedBanners.push('idhb-right-small-300');
    }
    setBanners(updatedBanners);
  }, [leftSize, rightSize]);

  React.useEffect(() => {
    adsRequest(banners);
  }, [banners]);

  React.useEffect(() => {
    const { events } = router;
    const handleRouteChange = () => {
      adsRequest(banners);
    }

    events.on('routeChangeComplete', handleRouteChange);
    events.on('hashChangeComplete', handleRouteChange);
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
      events.off('hashChangeComplete', handleRouteChange);
    }
  }, [router]);

  return (
    <Root>
      {leftSize > 0 && (
        <Sides>
          <AdvertisementSlotStyled
            slotIdentifier="idhb-left-300"
            variant={`${leftSize}x600`}
            elevation={1}
            alignment="right"
          />
          {leftSize >= 300 && (
            <AdvertisementSlotStyled
              slotIdentifier="idhb-left-small-300"
              variant="300x250"
              alignment="right"
              elevation={1}
            />
          )}
        </Sides>
      )}
      {children}
      {leftSize > 0 && (
        <Sides>
          <AdvertisementSlotStyled
            slotIdentifier="idhb-right-300"
            variant={`${leftSize}x600`}
            alignment="left"
            elevation={1}
          />
          {leftSize >= 300 && (
            <AdvertisementSlotStyled
              slotIdentifier="idhb-right-small-300"
              variant="300x250"
              alignment="left"
              elevation={1}
            />
          )}
        </Sides>
      )}
    </Root>
  );
}
